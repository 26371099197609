/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.header {
    color: var(--default-color);
    transition: all 0.5s;
    z-index: 997;
    background-color: var(--background-color);
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1); 
  }
  
  .header .topbar {
    background-color:#2c3293 !important;
    height: 65px;
    padding: 0;
    font-size: 14px;
    transition: all 0.5s;
  }
  
  .header .topbar .contact-info i {
    font-style: normal;
    color: var(--contrast-color);
    padding: 5px;
  }
  
  .header .topbar .contact-info i a,
  .header .topbar .contact-info i span {
    padding-left: 5px;
    color: var(--contrast-color);
  }
  
  @media (max-width: 575px) {
    .header .topbar .contact-info i a,
    .header .topbar .contact-info i span {
      font-size: 13px;
    }
  }
  
  .header .topbar .contact-info i a {
    line-height: 0;
    transition: 0.3s;
  }
  
  .header .topbar .contact-info i a:hover {
    color: var(--contrast-color);
    text-decoration: underline;
  }
  
  .header .topbar .social-links a {
    color: color-mix(in srgb, var(--contrast-color), transparent 40%);
    line-height: 0;
    transition: 0.3s;
    margin-left: 20px;
  }
  
  .header .topbar .social-links a:hover {
    color: var(--contrast-color);
  }
  
  .header .branding {
    height: 100px;
    padding: 10px 0;
  }
  
  .header .logo {
    line-height: 1;
  }
  
  .header .logo img {
    max-height: 66px;
    margin-right: 8px;
  }
  
  .header .logo h1 {
    font-size: 30px;
    margin: 0;
    font-weight: 700;
    color: var(--heading-color);
  }
  
  .header .cta-btn,
  .header .cta-btn:focus {
    color: var(--contrast-color);
    background: rgb(187, 22, 22);
    font-size: 14px;
    padding: 8px 25px;
    margin: 0 0 0 30px;
    border-radius: 50px;
    transition: 0.3s;
    border: none;
    
  }
  
  .header .cta-btn:hover,
  .header .cta-btn:focus:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, rgb(104, 12, 12) , transparent 15%);
  }

  .blink {
    -webkit-animation: blink-animation 1s infinite;
    -moz-animation: blink-animation 1s infinite;
    -o-animation: blink-animation 1s infinite;
    animation: blink-animation 1s infinite;
  }
  
  @-webkit-keyframes blink-animation {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  @-moz-keyframes blink-animation {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  @-o-keyframes blink-animation {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  @keyframes blink-animation {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  
  
  @media (max-width: 1200px) {
    .header .logo {
      order: 1;
    }
  
    .header .cta-btn {
      order: 2;
      margin: 0 15px 0 0;
      padding: 6px 15px;
    }
  
    .header .navmenu {
      order: 3;
    }
  }
  
  .scrolled .header {
    box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
  }
  
  .scrolled .header .topbar {
    height: 0;
    visibility: hidden;
    overflow: hidden;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Desktop Navigation */
  @media (min-width: 1200px) {
    .navmenu {
      padding: 0;
     
    }
    
    .navmenu ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
    }
    
    .navmenu li {
      position: relative;
    }
    
    .navmenu > ul > li {
      white-space: nowrap;
      padding: 15px 14px;
    }
    
    .navmenu > ul > li:last-child {
      padding-right: 0;
    }
    
    .nav-link {
      color: var(--nav-color);
      font-size: 15px;
      padding: 0 2px;
      font-family: var(--nav-font);
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: color 0.3s, background-color 0.3s;
      position: relative;
    }
    
    .nav-link:hover,
    .nav-link:focus {
      color: var(--nav-hover-color);
      background-color: var(--nav-hover-bg-color); /* Optional background color on hover */
    }
    
    .nav-link:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -6px;
      left: 0;
      background-color: var(--nav-hover-color);
      visibility: hidden;
      width: 0;
      transition: all 0.3s ease-in-out;
    }
    
    .nav-link:hover:before,
    .navmenu li:hover > .nav-link:before,
    .navmenu .active:before {
      visibility: visible;
      width: 100%;
    }
    
    .navmenu li:hover > .nav-link,
    .navmenu .active,
    .navmenu .active:focus {
      color: var(--nav-hover-color);
    }
    
  
    .navmenu .dropdown ul {
      margin: 0;
      padding: 10px 0;
      background: var(--nav-dropdown-background-color);
      display: block;
      position: absolute;
      visibility: hidden;
      left: 14px;
      top: 130%;
      opacity: 0;
      transition: 0.3s;
      border-radius: 4px;
      z-index: 99;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
  
    .navmenu .dropdown ul li {
      min-width: 200px;
    }
  
    .navmenu .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      color: var(--nav-dropdown-color);
    }
  
    .navmenu .dropdown ul a i {
      font-size: 12px;
    }
  
    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover > a {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .dropdown:hover > ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
  
    .navmenu .dropdown .dropdown ul {
      top: 0;
      left: -90%;
      visibility: hidden;
    }
  
    .navmenu .dropdown .dropdown:hover > ul {
      opacity: 1;
      top: 0;
      left: -100%;
      visibility: visible;
    }
  }
  
  /* Mobile Navigation */
  @media (max-width: 1199px) {
    
  
    .navmenu {
      padding: 0;
      z-index: 9997;
    }
  
    .navmenu ul {
      display: none;
      position: absolute;
      inset: 60px 20px 20px 20px;
      padding: 10px 0;
      margin: 0;
      border-radius: 6px;
      background-color: var(--nav-mobile-background-color);
      border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
      box-shadow: none;
      overflow-y: auto;
      transition: 0.3s;
      z-index: 9998;
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: var(--nav-dropdown-color);
      padding: 10px 20px;
      font-family: var(--nav-font);
      font-size: 17px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: 0.3s;
      background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    }
  
    .navmenu a i:hover,
    .navmenu a:focus i:hover {
      background-color: var(--accent-color);
      color: var(--contrast-color);
    }
  
    .navmenu a:hover,
    .navmenu .active,
    .navmenu .active:focus {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .active i,
    .navmenu .active:focus i {
      background-color: var(--accent-color);
      color: var(--contrast-color);
      transform: rotate(180deg);
    }
  
    .navmenu .dropdown > .dropdown-active {
      display: block;
      background-color: rgba(33, 37, 41, 0.03);
    }
  
    
  }
  .social{
    background-color: #571c92;
    border-radius: 30px;
    padding: 10px;
    color: rgb(255, 255, 255) !important;
    font-size: 1rem;
  }
  .cart {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 50px ;
    margin-right: 30px;
    font-size: 1.5rem;
  
  }
  
  .cart .fa-shopping-cart {
    font-size: 1.5rem;
  }
  
  .cart .badge {
    position: absolute;
    top: -15px;
    right: -15px;
    background-color: red;
    color: white;
    border-radius: 90%;
    padding: 3px 7px;
    font-size: 0.75rem;
  }
  
  @media (max-width: 769px) {
  .header .logo img {
    max-height: 36px;
    margin-right: 8px;
  }
  .cart {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 50px ;
    margin-right: 30px;
    font-size: 1.2rem;
  
  }
  .header .branding {
    height: 60px;
    padding: 10px 0;
  }
  .header .topbar {
    background-color: var(--accent-color);
    height: px;
    padding: 0;
    font-size: 14px;
    transition: all 0.5s;
  }
  .mobile-nav-toggle{
    display: flex;
    justify-content: flex-end;
    font-size: 1.4rem;
    margin-right: 10px;
    display: block !important;
  }

}
@media (max-width: 1024px) {
  .mobile-nav-toggle{
    display: flex;
    justify-content: flex-end;
    font-size: 1.4rem;
    margin-right: 10px;
    display: block !important;
  }
}

/* Header.css */

.mobile-nav-toggle{
  display: flex;
  justify-content: flex-end;
  font-size: 1.4rem;
  margin-right: 10px;
  display: none;
}


.topbar {
  background-color: #f8f9fa;
  padding: 10px 0;
  overflow: hidden !important;
}

.contact-info {
  display: flex;
  align-items: center;
}

.contact-info i {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.contact-info i span {
  margin-left: 5px;
}

.social-links .social {
  margin-left: 15px;
  color: #333;
}

.social-links .social:hover {
  color: #007bff;
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}


