/*--------------------------------------------------------------
# Stats Counter Section
--------------------------------------------------------------*/
.stats-counter .stats-item {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    
  }
  .stats-counter{
    margin-bottom: 5px;
    margin-top: 10px;
    margin-left: 80px;
    margin-right: 80px;
    height: 250px;
  }
  
  .stats-counter .stats-item i {
    color: rgb(184, 184, 215);
    font-size: 42px;
    line-height: 0;
    margin-right: 20px;
  }
  
  .stats-counter .stats-item span {
    color: rgb(255, 255, 255);
    font-size: 36px;
    display: block;
    font-weight: 600;
  }
  
  .stats-counter .stats-item p {
    padding: 0;
    margin: 0;
    font-family: var(--heading-font);
    font-size: 16px;
    color: rgb(213, 213, 230);
  }
  .sticon{
    color: rgb(255, 255, 255) !important;
  }
  .section-title{

    display: flex;
    justify-content: center;
}

@media (max-width: 769px) {

  .stats-counter{
    margin-bottom: 5px;
    margin-top: 10px;
    margin-left: 80px;
    margin-right: 80px;
    height: auto;
  }

}
.main1234 {
  padding-bottom: 30px; /* Adjust this value as needed */
}