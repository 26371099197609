/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */
:root {
  --default-font: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Poppins", sans-serif;
  --nav-font: "Raleway", sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root {
  --background-color: #ffffff; /* Background color for the entire website, including individual sections */
  --default-color: #444444; /* Default color used for the majority of the text content across the entire website */
  --heading-color: #2c4964; /* Color for headings, subheadings and title throughout the website */
  --accent-color: #1977cc; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --surface-color: #ffffff; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
  --contrast-color: #ffffff; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
  --nav-color: #2c4964; /* The default color of the main navmenu links */
  --nav-hover-color: #1977cc; /* Applied to main navmenu links when they are hovered over or active */
  --nav-mobile-background-color: #ffffff; /* Used as the background color for mobile navigation menu */
  --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
  --nav-dropdown-color: #2c4964; /* Used for navigation links of the dropdown items in the navigation menu. */
  --nav-dropdown-hover-color: #1977cc; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */

.light-background {
  --background-color: #f1f7fc;
  --surface-color: #ffffff;
}

.dark-background {
  --background-color: #060606;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #252525;
  --contrast-color: #ffffff;
}

/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General Styling & Shared Classes
--------------------------------------------------------------*/
body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
}

a {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  color: color-mix(in srgb, var(--accent-color), transparent 25%);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}


.social{
  background-color: #571c92;
  border-radius: 30px;
  padding: 10px;
  color: rgb(255, 255, 255) !important;
  font-size: 1rem;
}

.main {
  padding-bottom: 160px; /* Adjust this value as needed */
}

.sitename{
  font-size: 1.0rem !important;
  font-family: Arial, Helvetica, sans-serif;
}

.container {
  display: flex;
  height: 80px;
}
.menubt {
  display: flex;
  margin-left: 925px;
  font-size: 1.2rem;
  font-weight: bold;

}
a {
  
  text-decoration: none !important;
}

.menubt a {
  text-decoration: none;
  color: inherit;
}

.menubt a:hover {
  text-decoration: underline !important; /* Add underline on hover */
  color: #6041db; /* Optional: change text color on hover */
  transition: color 0.3s; /* Optional: smooth transition for color */
}

.menubt p {
  margin: 0;
  padding: 10px; /* Adjust padding as necessary */
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 1000;
}

.scroll-to-top:hover {
  background-color: #0056b3;
}

.scroll-to-top1 {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 1000;
  margin-bottom: 47px;
}


.scroll-to-top1:hover {
  background-color: #0056b3;
}
/*--------------------------------------------------------------
<--------------------- #  Footer Page Css ------------------->
--------------------------------------------------------------*/
.footer {
  --heading-font: var(--default-font);
  color: var(--default-color);
  background-color: darkslateblue;
  font-size: 14px;
  padding: 40px 0px 0px 0px;
  position: relative;
  margin-bottom: -25px;
  
}

.footer .icon {
  color: var(--accent-color);
  margin-right: 15px;
  font-size: 24px;
  line-height: 0;
}

.footer h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}

.footer .address p {
  margin-bottom: 0px;
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 16px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  margin-right: 10px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: var(--accent-color);
  border-color: var(--accent-color);
}

/* .footer .copyright {
  padding-top: 20px;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
} */

/* .footer .copyright p {
  margin-bottom: 0;
} */

.footer .credits {
  margin-top: 5px;
  font-size: 13px;
  color: rgb(234, 234, 234) !important;
  margin: 15px;
}
.footlinks{
  color: rgb(255, 242, 54);
}
.container1{
  margin-left: 25px !important;
  overflow: hidden;
  
}
.container12{
 padding: 10px;
 overflow: hidden;
 color: white;
 background-color: #312f2f;

}


.rules{
  background-color: red;
  padding-left: 70px;
  margin-top: 20px;
  padding-right: 70px;
  padding-top: 25px;
  height: 155px;
  font-size: 1.1rem;
  font-family: var(--default-font);
 
}
.footer-quicklink{
  font-family: var(--default-font);
}
@media (max-width: 768.99px){

  .rules{
    background-color: red;
    padding-left: 20px;
    margin-top: 20px;
    padding-right: 20px;
    padding-top: 25px;
    height: 255px;
    font-size: 1.1rem;
    font-family: var(--default-font);
  }
  
}


@media (max-width: 557.99px){

  .rules{
    background-color: red;
    /* padding-left: 70px; */
    margin-top: 20px;
    /* padding-right: 70px; */
    padding-top: 25px;
    height: 300px;
    font-size: 0.9rem;
  
  }

  
  
}
@media (max-width: 375.99px){

  .rules{
    background-color: red;
    /* padding-left: 70px; */
    margin-top: 20px;
    /* padding-right: 70px; */
    padding-top: 25px;
    height: 300px;
    font-size: 0.9rem;
  }
}

@media (max-width: 320.99px){

  .rules{
    background-color: red;
    
    margin-top: 20px;
    
    padding-top: 25px;
    height: 355px;
    font-size: 0.9rem;
    position: relative;
  }
}




/*--------------------------------------------------------------
<--------------------- #  home page  Css ------------------->
--------------------------------------------------------------*/
/*--------------------------------------------------------------
<--------------------- #  Carousel  Css ------------------->
--------------------------------------------------------------*/
.carousel-item img {
  width: 100%;
  height: 500px; 
  object-fit: cover;
  
}
.carousel-indicators{
  display: none !important;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  background-color: #f1f1f1; /* Adjust background color as needed */
  padding: 10px 0;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 25s linear infinite;
  width: 100%;
  color: rgb(190, 2, 2);
  font-weight: bold;
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.search{
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  padding-right: 60px;
}

h5{
  font-family:Arial, Helvetica, sans-serif !important;
}
h3{
  font-family: fantasy;
}
.order {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color:transparent;
  padding: 10px 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-bottom: 15px !important;
  
}

.order-form{
  margin-left: 50px;
  margin-right: 50px;
  box-shadow: 7px 6px 6px rgba(0, 0, 0, 0.1) !important;
  padding-bottom: 20px;
  margin-bottom: 20px;
  
}

.form-control{
  border: 1px solid #b7cee7 !important;
  background-color: #edf2f8 !important;
}

.order {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  padding: 30px;
  transition: background-color 0.3s ease;

}

.order:hover {
  background-color: #e9ecef; /* Light grey color on hover */
}
.col1, .col2, .col3 {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.col1:hover, .col2:hover, .col3:hover {
  background-color: #c3d1e0; 
  transform: scale(1.02); 
}
.content-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 25px;
  
 }

 .content-section1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #edebc1;
}

.content-text {
  flex: 1 1 50%; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.topimg {
  flex: 1 1 45%; 
  display: flex;
  
  align-items: flex-end;
  padding: 10px;
}


.content-text{
  padding: 25px;
  
}
.adlist{
  display: flex;
 justify-content: space-evenly;

}


.tick {
  background-color: rgb(170, 3, 3); 
  border-radius: 50%; 
  color: white; 
  padding: 0px; 
  font-size: 0.8em; 
  display: inline-flex; 
  align-items: center; 
  justify-content: center;
}

/* home section  product cards*/

.newarrival{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  padding-top: 35px;
  padding-bottom: 25px;
  padding-left: 85px;
  padding-right: 85px;
  
}

.newarrival {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 20px; 
  row-gap: 60px;
}

.card-container {
  display: flex;
  justify-content: center; /* Center card within its container */
}


.card-container .custom-shadow {
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for scaling and shadow */
}

.card-container .custom-shadow:hover {
  transform: scale(0.8); /* Scale up the card */
  box-shadow: 10px 10px 20px 15px rgba(0, 0, 0, 0.2);
}

.card-container .img12 {
  transition: transform 0.3s ease; /* Smooth transition for image scaling */
}

.card-container .custom-shadow:hover .img12 {
  transform: scale(0.8); /* Scale up the image */
}

.newarr{
  background-color: transparent;
  
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
.custom-shadow {
  box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.2);
}

.img12{
  padding: 10px;
  height: 200px;
  border-radius: 25px;
}

.head{
  margin-top: 80px;
  padding: 10px;
  font-weight: bold;
  font-family:'Courier New', Courier, monospace;
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 2.8rem;
 }
 
 .imgname{
  display: flex;
  justify-content: center;
  font-size: 0.9rem !important;
  font-weight: bold !important;
 }

 .horizontal-line-text-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.line {
  flex: 1;
  border: 0;
  border-top: 2px solid #000; /* Adjust color and width as needed */
  margin: 0 10px; /* Space between lines and text */
  margin-top: 55px;
}

.text123 {
  white-space: nowrap;
  font-size: 1.8rem; /* Adjust font size as needed */
  font-weight: bold; /* Optional: make text bold */
  margin-top: 60px;
}


@media (max-width: 768.99px){
  .menubt{

    margin-left: 260px !important;
    font-size: 1.0rem !important;
   }
     .cart{
      
      margin-left: 30px;
    }
  
}
@media (max-width: 1024.99px){
  .menubt{

   margin-left: 450px !important;
   font-size: 1.0rem;
  }
  .cart{
    
    margin-left: 30px;
  }

  .main1{
    padding-left: 5px;
    padding-right: 5px;
  }
  .main123 {
    height: 200px; /* Adjust this value as needed */
    overflow: hidden; /* Optional: hides overflow content */
  }

  .carousel-item img {
    height: 100%; /* Ensure the image fits the container height */
    object-fit: cover; /* Cover the area without distortion */
  }
  .sitename{
    font-size: 1.2rem !important;
  }
 
}

@media (max-width: 430.99px){
  .menubt{

    margin-left: 60px !important;
    font-size: 1.0rem;
   }

  .cart{
    
    margin-left: 40px;
  }

  .buy{
    margin-left: 100px !important;
  }
  .order-form{
    margin-right: 5px !important;
    margin-left: 5px;
  }

  .product-table{
    margin-right: 5px !important;
    margin-left: 5px;
  }
  .main1{
    padding-left: 5px;
    padding-right: 5px;
  }
  .order{
    /* position: fixed;
    height: 125px;
    overflow: hidden; */
    display: none;
   }
  .estimate{
     display: flex;
     justify-content: space-evenly;
     font-size: 0.6rem;
     width: 100% !important;
     
  }
  .col1{
    width: 95px !important;
    height: 95px !important;
  }
  .col2{
    width: 95px !important;
    height: 95px !important;
  }
  .col3{
    width: 95px !important;
    height: 95px !important;
  }
  .main123 {
    height: 200px; /* Adjust this value as needed */
    overflow: hidden; /* Optional: hides overflow content */
  }

  

  
}
@media (max-width: 375.99px){
  .menubt{

   margin-left: 30px !important;
   font-size: 1.0rem;
  }
  .cart{
    
    margin-left: 30px;
  }

  .main1{
    padding-left: 5px;
    padding-right: 5px;
  }
  .main123 {
    height: 200px; /* Adjust this value as needed */
    overflow: hidden; /* Optional: hides overflow content */
  }
  .product-table{
    overflow:scroll;
  }
  .carousel-item img {
    height: 100%; /* Ensure the image fits the container height */
    object-fit: cover; /* Cover the area without distortion */
  }
  .sitename{
    font-size: 1.2rem !important;
  }
 
}
@media (max-width: 320.99px){
  .menubt{

   margin-left: 40px;
   font-size: 0.8rem;
  }
  .cart{
    margin-left: 10px;
  }

  .main1{
    width: 100% !important;
    overflow: hidden !important;
    
  }
  .estimate{
    display: flex;
    flex-direction: column;
    
    
  }
  .search{
    width: 375px;
  
  }
   .main{
    width: 100% !important;
    overflow: hidden;
  }
  .product-table{
    overflow-x: auto;
  }
  .main123 {
    height: 200px; /* Adjust this value as needed */
    overflow: hidden; /* Optional: hides overflow content */
  }

  .carousel-item img {
    height: 100%; /* Ensure the image fits the container height */
    object-fit: cover; /* Cover the area without distortion */
  }
  .sitename{
    font-size: 1.0rem !important;
  }
 
}
.category-header{
  background-color: #eee !important;


}
.ctgyname{
  
    display: flex;
    justify-Content: center;
    color: rgb(225, 0, 0);
    font-weight: 600;
}


.countadd{
  background-color: #eee !important;
  color: #555 !important;
  border: 1px solid #ccc!important;
}

.counter-button {
  font-size: 14px; 
  width: 30px; 
  height: 30px;
  margin: 0 5px;
  line-height: 30px; 
  text-align: center; 
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important; 
  background-color: #d4e3f3 !important;
  color: black !important;
  border: none !important;
}

.counter-display {
  font-size: 14px; 
  width: 30px;
  height: 30px; 
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd; 
  border-radius: 4px; 
  margin: 0 10px;
}

/* Add this CSS to your stylesheet */

@keyframes blinkButton {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.counter-btn{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  
}



.minifooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color:#d30606;
  color: white;
  margin: 0;
  padding: 10px;
  position: fixed; /* Fixed positioning */
  bottom: 70px; /* Stick to the bottom of the viewport */
  left: 0; /* Ensure it stretches across the full width */
  z-index: 1000; /* Ensure it's on top of other content */
  overflow: hidden; /* Ensure no overflow issues */
}
.rate p {
  display: inline-block;
  margin: 0;
  margin-right: 10px; /* Add space between p tags */
}

.rate span {
  display: block;
  margin-top: 5px; /* Space between p and span */
}
@media (min-width: 427px) {
  .minifooter {
    display: none; /* This would hide the footer on screens wider than 425px */
  }
  .minifooter1 {
    display: none; /* This would hide the footer on screens wider than 425px */
  }
  
}

@media (max-width: 426px) {
  .minifooter {
    display: flex; /* Ensure it is displayed at larger screen sizes */
  }
  .main {
    padding-bottom: 65px !important; /* Adjust this value as needed */
  }
  .sitename {
    font-size: 0.8rem !important;
}
}

  
  
/* Hide these columns on small screens (below 426px) */
/* Hide columns on small screens */
@media (max-width: 426px) {
  .price-column,
  .qty-column,
  .qty-per-box-column,
  .total-column {
    display: none;
  }
  .add-btn{
    width: 95px;
  }

  .scroll-to-top1 {
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 1000;
    margin-bottom: 47px;
  }
  .scroll-to-top {
    position: fixed;
    bottom: 75px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 1000;
  }
}

/* Show columns on larger screens */
@media (min-width: 427px) {
  .price-column,
  .qty-column,
  .qty-per-box-column,
  .total-column {
    display: table-cell;
  }
  .product-detail-header{
    display: none;
  }
  .name-column {
    text-align: left !important;
    width: 400px;
  }
}
.search-box-container {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.search-box {
  position: relative;
  width: 100%;
  max-width: 600px; /* You can adjust this max-width as needed */
}

.search-box input {
  width: 100%;
  padding-left: 2.5rem; /* Add padding for the icon */
}

.search-box .fa-search {
  position: absolute;
  left: 0.75rem; /* Space between the icon and input field edge */
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #6c757d;
}

/* Responsive adjustments */
@media (max-width: 426px) {
  .search-box {
    width: 100%;
    max-width: none; /* Remove max-width on small screens */
  }

  .search-box .fa-search {
    font-size: 0.9rem; /* Adjust icon size if needed */
  }
}

@media (max-width: 320px) {
  .search-box .fa-search {
    font-size: 0.8rem; /* Adjust icon size for extra-small screens */
  }
}

.floating-whatsapp {
  position: fixed;
  bottom: 100px;
  right: 20px;
  background-color: #45d078; /* WhatsApp green color */
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.7);
  transition: transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.floating-whatsapp:hover {
  transform: scale(1.1);
}

.floating-whatsapp a {
  color: white;
  font-size: 35px;
}

.floating-whatsapp-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.floating-whatsapp .fa-whatsapp {
  font-size: 30px;
  color: #ffffff;
}

.chat-box {
  position: absolute;
  bottom: 110px; /* Adjust based on your icon size */
  right: 0;
  width: 300px;
  background: rgb(255, 255, 255);
  border: 1px solid #ddd;
  border-radius: 7px; /* Add this line for rounded corners */
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  border: none;
  z-index: 1001;
  overflow: hidden;
}

.wats-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold; /* Makes text bold */
  margin-bottom: 10px; /* Space below the header */
  background-color:#128C7E;
  color: white;
  height: 35px;
}

.wats-header p {
  margin: 0;
  padding-left: 10px;
}

.wats-header .close-button {
  background:#128C7E !important;
  color: white;
  border: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  line-height: 20px;
  margin-right: 10px !important;
}

.chat-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  margin-top: 20px;
}

.chat-content textarea {
  width: 100%;
  height: 60px;
  margin: 10px 0;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-left: 10px;
}

.chat-content button {

  background:rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.wats-content{
  background-color: white;
  margin-left: 10px;
  margin-right: 10px;
  height: 5vh;
  border-radius: 5px;
}

.wats-content p{
  color: grey;
  margin-left: 10px;
  margin-top: 5px;
}


/* Customize scrollbar for Webkit browsers */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb on hover */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track (background) */
}

.dos{
  display: flex;
 padding: 10px;
}
.doss{
  border: none !important;
  background-color: darkblue  !important;
  color: #ffffff !important;
  
}

.donts{
  margin-left: 15px !important;
  border: none !important;
  background-color: darkblue !important;
  color: #ffffff !important;
}

.doss:hover {
  background-color: #76cb00 !important;
  transform: scale(1.05); 
}


.donts:hover {
  background-color: #d20015 !important; 
  transform: scale(1.05);
}


.dos-content, .donts-content {
  margin-top: 20px;
  font-family: var(--default-font);
  
}

.dos-content p, .donts-content p {
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1rem !important;
  
}
/* --------------order estimate color footer */

.order {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color:transparent;
  padding: 10px 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-bottom: 15px !important;
  
}


.estimate {
  text-align: center;
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}



.col1{
  background-color: #1977cc;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}
.col2{
  background-color: #61cc19;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  color: black;
  text-decoration: none !important;
}
.col3{
  background-color: #a86f39;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  color: black;
  text-decoration: none !important;
}

/* --------------------------quick purchase------------------------ */


.product-table{
  margin-right: 5px !important;
  margin-left: 5px;
}

.table {
  width: 100%;
}


.table th, .table td {
  vertical-align: middle;
  text-align: center;
}


.table td {
  padding: 10px; /* Adjust as needed */
}



thead {
  background-color: #044895 !important; /* Background color for thead */
  color: white; /* Text color for thead */
  
}
.table-heading {
  text-align: center; /* Center align text */
  font-weight: bold; /* Make text bold */
  padding: 10px; /* Add padding */
}
.search{
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  padding-right: 60px;
}
.tab-img {
  max-width: 50px; /* Adjust as needed */
  margin-right: 10px; /* Space between image and button */
  text-align: center;
  vertical-align: middle;

}

.add-btn{
  background-color: #a50000 !important;
  border: none !important;
  
}

h5{
  font-family: cursive, Verdana, sans-serif;
}

/* --------about-section----------------- */

.about-section{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 25px;
 }
/* Responsive Styles */

/* Large screens: 1440px and up */
@media (min-width: 1440px) {
  .parallax-section h1 {
    font-size: 3rem;
  }

  .parallax-section p {
    font-size: 1.2rem;
  }

  .content-text h3 {
    font-size: 2rem;
  }
}

/* Medium screens: 1024px to 1439px */
@media (max-width: 1439px) and (min-width: 1024px) {
  .parallax-section h1 {
    font-size: 2.5rem;
  }

  .parallax-section p {
    font-size: 1.1rem;
  }

  .content-text h3 {
    font-size: 1.8rem;
  }
}

/* Small screens: 768px to 1023px */
@media (max-width: 1023px) and (min-width: 768px) {
  .about-section {
    flex-direction: column;
    align-items: center;
  }

  .content-text {
    text-align: center;
  }

  .parallax-section h1 {
    font-size: 2rem;
  }

  .parallax-section p {
    font-size: 1rem;
  }

  .content-text h3 {
    font-size: 1.6rem;
  }
}

/* Extra small screens: 425px to 767px */
@media (max-width: 767px) and (min-width: 425px) {
  .about-section {
    flex-direction: column;
    align-items: center;
  }

 

  .content-text {
    text-align: center;
  }

  .parallax-section h1 {
    font-size: 2rem;
  }

  .parallax-section p {
    font-size: 1rem;
  }

  .content-text h3 {
    font-size: 1.6rem;
  }
}


/* Very small screens: 320px to 424px */
@media (max-width: 424px) and (min-width: 320px) {
  .about-section {
    flex-direction: column;
    align-items: center;
  }

 

  .content-text {
    text-align: center;
  }

  .parallax-section h1 {
    font-size: 2rem;
  }

  .parallax-section p {
    font-size: 1rem;
  }

  .content-text h3 {
    font-size: 1.6rem;
  }
}



.offcanvas-menu {
  list-style-type: none;
  padding: 0;
}

.offcanvas-menu li {
  margin: 10px 0;
}

.offcanvas-menu .nav-link {
  text-decoration: none;
  color: #000;
}


/* --------------------------------Responsive------------------------------ */


/* -------home------------ */

@media (max-width: 1440px) {
  .content-section {
    flex-direction: row;
  }
}
.productname{
  font-size: 0.8rem;
  font-weight: 700;
}
@media (max-width: 1024px) {
  .content-section {
    flex-direction: column;
  }
  
  .adlist {
    flex-direction: column;
  }
}

@media (max-width: 768px) {

  .topimg img {
    width: 100%;
  }

  .content-text h3 {
    font-size: 24px;
  }

  .content-text h4 {
    font-size: 20px;
  }

  .content-text p {
    font-size: 14px;
  }

  .adlist {
    flex-direction: row;
  }
  .content-section1 {
    flex-direction: column;
  }
  .content-text, .topimg {
    flex: 1 1 100%;
  }
  .topimg {
    order: 2; /* Ensure the image is at the bottom */
    justify-content: center; /* Center the image horizontally */
  }
  .content-text {
    order: 1;
    text-align: left;
  }
}

@media (max-width: 426px) {
  .topimg img {
    width: 100%;
  }

  .content-text h3 {
    font-size: 20px;
  }

  .content-text h4 {
    font-size: 18px;
  }

  .content-text p {
    font-size: 12px;
  }

  .adlist {
    display: flex;
    flex-direction: row;
  }

  .content-text, .topimg {
    flex: 1 1 100%;
  }
  .topimg {
    order: 2;
  }
  .content-text {
    order: 1;
    text-align: left;
  }
  .floating-whatsapp {
    position: fixed;
    bottom: 130px;
    right: 20px;
    background-color: #25d366; /* WhatsApp green color */
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.7);
    transition: transform 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  .productname{
    font-size: 0.8rem;
    font-weight: 700;
  }
}


@media (max-width: 375px) {
  .content-text h3 {
    font-size: 18px;
  }

  .content-text h4 {
    font-size: 16px;
  }

  .content-text p {
    font-size: 10px;
  }
}

@media (max-width: 320px) {
  .content-text h3 {
    font-size: 16px;
  }

  .content-text h4 {
    font-size: 14px;
  }

  .content-text p {
    font-size: 9px;
  }
}
/* ----card---------- */
.newarrival {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the cards */
  gap: 20px; /* Space between the cards */
  margin: 0 auto; /* Center the entire container */
}

.card-container {
  box-sizing: border-box;
  margin-bottom: 20px;
}

/* Default: 4 cards per row (large screens) */
@media (min-width: 1025px) {
  .card-container {
    flex: 1 1 calc(25% - 20px); /* 4 cards per row */
  }
}

/* 1024px and 768px: 4 cards per row */
@media (max-width: 1024px) {
  .card-container {
    flex: 1 1 calc(25% - 20px); /* 4 cards per row */
  }
}

/* 425px and below: 2 cards per row */
@media (max-width: 426px) {
  .newarrival {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    margin-left: 5px !important;
    margin-right: 5px;
    gap: 10px; /* Adjust space between cards */
    row-gap: 20px;
   
  }

  .text123{
    font-size: 1rem;
    margin-top: 50px;
    font-weight: bold;
  }

  .sub-banner{
    width: 170px !important;
    height: 200px !important;
    padding-left: 15px;
    padding-right: 15px;
    

  }

  .imgname{
    display: flex;
    justify-content: center;
    font-size: 0.7rem !important;
    font-weight: bold !important;
   }

  
}

/* 320px and below: 2 cards per row */
@media (max-width: 376px) {
  .newarrival {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    margin-left: 5px !important;
    margin-right: 5px;
    gap: 10px; /* Adjust space between cards */
    row-gap: 20px;
   
  }
  .text123{
    font-size: 1rem;
    margin-top: 55px;
    font-weight: bold;
  }

  .sub-banner{
    width: 280px !important;
    height: 340px !important;
  }
  .img12{
    height: 240px;
   }
}

.main-homepage {
  padding-bottom: 0px; /* Adjust this value as needed */
}


/* --------------privacy page responsive-------------------- */


.privacy-content {
  padding: 20px;
  padding-left: 65px !important;
  padding-right: 65px !important;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  word-wrap: break-word;
  white-space: normal;
  font-family: var(--default-font) !important;
  
}

@media (min-width: 1440px) {
  .privacy-content {
    padding: 20px;
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .privacy-content {
    padding: 18px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .privacy-content {
    padding: 15px;
    font-size: 14px;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (max-width: 425px) {
  .privacy-content {
    padding: 12px;
    font-size: 13px;
    
  }
}

@media (max-width: 375px) {
  .privacy-content {
    padding: 10px;
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  .privacy-content {
    padding: 8px;
    font-size: 11px;
  }
}


/* ----------terms and conditions---------- */

/* Base styles for terms-content 
.terms-content {
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  font-family: var(--default-font);
}

@media (min-width: 1440px) {
  .terms-content {
    padding: 40px;
  }
}

@media (max-width: 1024px) {
  .terms-content {
    padding: 30px;
  }
}

@media (max-width: 768px) {
  .terms-content {
    padding: 20px;
  }
}

@media (max-width: 425px) {
  .terms-content {
    padding: 15px;
  }
}

@media (max-width: 375px) {
  .terms-content {
    padding: 10px;
  }
}

@media (max-width: 320px) {
  .terms-content {
    padding: 8px;
  }
}*/

.officon{
  color: rgb(62, 45, 45);
}

/* Basic styles for the terms content */
.terms-content {
  padding: 20px;
  font-size: 1rem;
  line-height: 1.6;
  font-family: Arial, Helvetica, sans-serif;
  height: 480px;
}

/* Override any problematic inline styles */
.terms-content * {
  max-width: 100%; /* Ensure elements don't overflow */
  box-sizing: border-box;
}

/* Responsive design for small screens */
@media (max-width: 768px) {
  .terms-content {
    font-size: 0.9rem;
    padding: 15px;
  }

  .terms-content h1 {
    font-size: 1.5rem;
  }

  .terms-content p,
  .terms-content ul {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 426px) {
  .terms-content {
    font-size: 0.8rem;
    padding: 10px;
    height: 780px !important;
  }

  .terms-content h1 {
    font-size: 1.2rem;
  }

  .terms-content p,
  .terms-content ul {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media (max-width: 376px) {
  .terms-content {
    font-size: 0.8rem;
    padding: 10px;
    height: 980px !important;
  }
}
/* Basic styles for the shipping content */
.shipping-content {
  padding: 20px;
  font-size: 1rem;
  line-height: 1.6;
}

/* Override any problematic inline styles */
.shipping-content * {
  max-width: 100%; /* Ensure elements don't overflow */
  box-sizing: border-box;
}



/* Responsive design for small screens */
@media (max-width: 768px) {
  .shipping-content {
    font-size: 0.9rem;
    padding: 15px;
  }

  .shipping-content h1 {
    font-size: 1.5rem;
  }

  .shipping-content p,
  .shipping-content ul {
    margin-left: 10px;
    margin-right: 10px;
  }
  .productname {
    font-size: 12px; 
  }
  .image-column{
    width: 90px;
  }
}

@media (max-width: 425px) {
  .shipping-content {
    font-size: 0.8rem;
    padding: 10px;
  }

  .shipping-content h1 {
    font-size: 1.2rem;
  }

  .shipping-content p,
  .shipping-content ul {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.contactfooter{
 font-family: Arial, Helvetica, sans-serif;
 font-size: 1rem;
}

.minimenu {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  width: 100%;
  background-color:#a01717;
  color: white;
  margin: 0;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed; 
  bottom: 0; 
  left: 0; 
  z-index: 1000; 
  overflow: hidden;
}

@media (max-width: 426px) {
  
    .container12{
      padding: 10px;
      overflow: hidden;
      color: white;
      background-color: #312f2f;
      margin-bottom: 70px;
     }
  .image-column{
    width: 90px;
  }
  .productname {
    font-size: 12px; 
  }
  
}
@media screen and (max-width: 347px) {
  .quantity-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;  
    width: 100%;
  }

  .countadd {
    padding: 5px 10px;
  }

  .product-table img {
    width: 50px;
    height: auto;
  }

  .tab-img1 {
    display: flex;
    justify-content: center;
  }

  .productname {
    font-size: 12px; 
  }
}
