/*--------------------------------------------------------------
<-------------------# Contact Section ------------------------->
--------------------------------------------------------------*/
.contact .info-item {
    background-color: var(--surface-color);
    padding: 20px 0 30px 0;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  .contact{
    overflow: hidden;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  .main-head{
    display: flex;
    justify-content: center;
  }

  .main-head1{
    display: flex;
    justify-content: center;
    color: rgb(46, 22, 115);
  }
  .contact .info-item i {
    font-size: 20px;
    color: var(--accent-color);
    width: 56px;
    height: 56px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
    border: 2px dotted color-mix(in srgb, var(--accent-color), transparent 40%);
  }
  
  .contact .info-item h3 {
    font-size: 20px;
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0;
  }
  
  .contact .info-item p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
  
  .contact .php-email-form {
    background-color: var(--surface-color);
    height: 100%;
    padding: 30px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 575px) {
    .contact .php-email-form {
      padding: 20px;
    }
  }
  
  .contact .php-email-form input[type=text],
  .contact .php-email-form input[type=email],
  .contact .php-email-form textarea {
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
    color: var(--default-color);
    background-color: var(--surface-color);
    /* border-color: color-mix(in srgb, var(--default-color), transparent 80%); */
  }
  
  .contact .php-email-form input[type=text]:focus,
  .contact .php-email-form input[type=email]:focus,
  .contact .php-email-form textarea:focus {
    border-color: var(--accent-color);
  }
  
  .contact .php-email-form input[type=text]::placeholder,
  .contact .php-email-form input[type=email]::placeholder,
  .contact .php-email-form textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  .contact .php-email-form button[type=submit] {
    color: var(--contrast-color);
    background: var(--accent-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 50px;
  }
  .contact .php-email-form button[type=submit]{
    background-color: #2e2ef6;
    color: white;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background-color: rgb(11, 4, 37);
  }
  @media (max-width: 426px) {
    .about-title1 {
      display: flex;
      margin-left: 50px;
    }
    .about-title {
      display: flex;
      margin-left: 50px;
    }
    .headh2 {
      display: flex;
      margin-left: 50px !important;
    }
  }
  
  .contact-head {
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 10px;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    position: relative;
    display: inline-block;
    margin-left: 600px;
    font-size: 1.4rem;
    color: darkslateblue;
  }
  
  .contact-head::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgb(255, 43, 43);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .contact-head:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  
  .iconalign{
    display:flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .homebtn{
    margin-left: 200px;
  }
  .sticon1{
    color: #2e2ef6 !important;
  }