.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff; /* Adjust the background color as needed */
}

.crackers-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.crackers-gif {
  width: 100px; /* Adjust the size of the GIF */
  height: auto;
}

.loader-container p {
  font-size: 1.2em;
  color: #333;
}
